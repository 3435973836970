import { billingServicesApiSlice } from 'redux/services/billingServicesApiSlice';

export const apideckSlice = billingServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createVaultSession: builder.mutation<
      {
        url: string;
      },
      { accountId: string }
    >({
      query: ({ accountId }) => ({
        url: `/accounts/${accountId}/integrations/apideck`,
        method: 'POST'
      })
    })
  })
});

export const { useCreateVaultSessionMutation } = apideckSlice;
